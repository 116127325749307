import type { OrderConfig } from './order.types';

export const orderConfig: OrderConfig = {
  paths: {
    basket: {
      default: '/warenkorb',
    },
    login: {
      default: '/kasse/registrieren',
    },
    delivery: {
      default: '/kasse/lieferservice',
    },
    changeDelivery: {
      default: '/kasse/lieferservice-aendern',
    },
    payment: {
      default: '/kasse/zahlung',
    },
    changePayment: {
      default: '/kasse/zahlung-aendern',
    },
    paymentprotection: {
      default: '/kasse/ratenschutz',
    },
    paymentprotectionupdate: {
      default: '/kasse/ratenschutz-aktualisierung',
    },
    delayedPayment: {
      default: '/kasse/zahlpause',
    },
    premium: {
      default: '/kasse/premium',
    },
    checkandorder: {
      default: '/kasse',
    },
    orderconfirmation: {
      default: '/kasse/auftrag',
    },
    noOrder: {
      default: '/kasse/hinweis',
    },
    afterPayment: {
      default: '/kasse/bezahlt/',
    },
    myAccountBase: {
      default: '/mein-konto',
      'fr-CH': '/mon-compte',
      'nl-NL': '/mijn-account',
    },
    myAccountOverview: {
      default: '/mein-konto/uebersicht',
      'fr-CH': '/mon-compte/apercu',
      'nl-NL': '/mijn-account/overzicht',
    },
    myAccountPersonalData: {
      default: '/mein-konto/meine-persoenlichen-daten',
      'fr-CH': '/mon-compte/mes-donnees-personnelles',
      'nl-NL': '/mijn-account/mijn-persoonlijke-gegevens',
    },
    myAccountOrders: {
      default: '/mein-konto/meine-bestellungen-und-lieferungen',
      'fr-CH': '/mon-compte/mes-commandes-et-livraisons',
      'nl-NL': '/mijn-account/mijn-bestellingen-en-leveringen',
    },
    myAccountBookings: {
      default: '/mein-konto/meine-buchungen',
      'fr-CH': '/mon-compte/mes-reservations',
      'nl-NL': '/mijn-account/mijn-boekingen',
    },
    myAccountAddresses: {
      default: '/mein-konto/meine-lieferanschriften',
      'fr-CH': '/mon-compte/mes-adresses-de-livraison',
      'nl-NL': '/mijn-account/mijn-leveringsadressen',
    },
    myAccountPayback: {
      default: '/mein-konto/payback',
      // 'fr-CH': '/mon-compte/payback',
      // 'nl-NL': '/mijn-account/payback',
    },
    wishlist: {
      default: '/merkzettel',
    },
    paylink: {
      default: '/paylink',
    },
    paylinkSuccess: {
      // this is to turn off geo country layer on paylink success page
      default: '/paylink/returning-success/',
    },
  },
  externalUrls: {
    agb: {
      default: '/service-hilfe/ueber-uns/agb/',
      'fr-CH': '/fr/aide-service/qui-sommes-nous/cgv',
      'nl-NL': '/special/algemene-voorwaarden',
    },
    revocation: {
      default: '/service-hilfe/ueber-uns/agb/',
      'fr-CH': '/fr/aide-service/qui-sommes-nous/cgv',
      'nl-NL': '/special/herroepingsrecht',
    },
    joe: {
      default: 'https://www.joe-club.at/registrieren',
    },
  },
  seals: {
    ehi: {
      enabled: false,
      url: '',
    },
    trustedShops: {
      enabled: false,
      url: '',
    },
    staatswappen: {
      enabled: false,
      url: '',
    },
    handelsverband: {
      enabled: false,
      url: '',
    },
    ecommerce: {
      enabled: false,
      url: '',
    },
    oeonline: {
      enabled: false,
      url: '',
    },
    dqs: {
      enabled: false,
      url: '',
    },
  },
  premium: {
    enabled: false,
  },
  joe: {
    enabled: false,
  },
  assets: {
    seals: {
      getSealImage: ({ language, sealName }) => {
        switch (sealName) {
          case 'trustedShops':
            return `https://bilder.baur.de/i/empiriecom/io/${language}/trustedshops.jpg`;
          case 'ehi':
            return `https://bilder.baur.de/i/empiriecom/io/${language}/siegel_ehi.png`;
          case 'staatswappen':
            return `https://bilder.baur.de/i/empiriecom/io/${language}/staatswappen.png`;
          case 'handelsverband':
            return `https://bilder.baur.de/i/empiriecom/io/${language}/handelsverband.jpg`;
          case 'ecommerce':
            return `https://bilder.baur.de/i/empiriecom/io/${language}/ecommerce.jpg`;
          case 'oeonline':
            return `https://bilder.baur.de/i/empiriecom/io/${language}/oeonline.png`;
          case 'dqs':
            return `https://bilder.baur.de/i/empiriecom/io/${language}/ISO-14001-D.png`;
          default:
            return '';
        }
      },
    },
    premium: {
      getPremiumImage: ({ device }) =>
        `https://bilder.universal.at/empiriecom/io/universalat/de/BS3_CheckOut_${device}.jpg`,
    },
    gifs: {
      success: {
        url: 'https://bilder.baur.de/i/empiriecom/io/de/thank_you_1_transparent.gif',
      },
    },
  },
  layers: {
    servicelayer: {
      default: '',
    },
  },
  hasCo2Compensation: true,
  hasFlexikontoAdvantageClub: false,
  payment: {
    americanExpress: false,
    clickToPay: false,
  },
  payback: {
    enabled: false,
  },
  accountInformation: {
    bankTransferEnabled: true,
    bicAvailable: true,
  },
  recommendations: {
    wishlist: {
      similarProducts: {
        isEnabled: true,
        type: 'prudsysProductsSimilar',
      },
    },
  },
};
